import {Component, Input}    from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-menu-popover',
  templateUrl: 'menu-popover.component.html',
})
export class MenuPopoverComponent
{
    @Input() public menuItems: any[];

    public constructor(
      private popoverController: PopoverController
    )
    {
    }

    public async onClick(boundFunction: any)
    {
        if (boundFunction) {
            boundFunction();
        }

        await this.popoverController.dismiss();
    }
}
