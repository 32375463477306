import {ApplicationRef, Injectable} from '@angular/core';
import {SwUpdate}                   from '@angular/service-worker';
import {ToastController}            from "@ionic/angular";
import {concat, first, interval}    from "rxjs";

@Injectable()
export class AppUpdateService
{
    constructor(
        private appRef: ApplicationRef,
        private readonly updates: SwUpdate,
        private toastController: ToastController
    )
    {
        const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
        const everySixHours$ = interval(6 * 60 * 60 * 1000);
        const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

        everySixHoursOnceAppIsStable$.subscribe(async () => {
            try {
                updates.versionUpdates.subscribe(async event => {
                    switch (event.type) {
                        case 'VERSION_READY':
                            const toast = await this.toastController.create({
                                // @ts-ignore
                                message: `New version available. Load version ${event.latestVersion.appData.version}?`,
                                position: 'bottom',
                                buttons: [
                                    {
                                        text: 'Update',
                                        icon: '',
                                        role: 'confirm',
                                        handler: () => location.reload(),
                                    },
                                    {
                                        text: 'Cancel',
                                        role: 'cancel',
                                    },
                                ]
                            });

                            await toast.present();
                            break;

                        case 'VERSION_INSTALLATION_FAILED':
                            console.error(`Failed to install app version '${event.version.hash}': ${event.error}`);
                            break;
                    }
                });
            } catch (error) {
                console.error('Failed to check for updates:', error);
            }
        });
    }
}
