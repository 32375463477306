<ion-content>
    <ion-list lines="full">
        <ion-item
            *ngFor="let item of menuItems"
            [button]="item.isButton"
            [detail]="item.isDetail"
            [disabled]="item.isDisabled"
            [innerHTML]="item.text | safeHtml"
            (click)="onClick(item.action)"
        >
        </ion-item>
    </ion-list>
</ion-content>
