import {Injectable}  from '@angular/core';
import {BaseService} from './base.service';
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class StorageService extends BaseService
{
    public prefix = environment.storagePrefix;

    constructor()
    {
        super();
    }

    public write(key: string, data: any)
    {
        localStorage.setItem(`${this.prefix}_${key}`, data);
    }

    public read(key: string)
    {
        return localStorage.getItem(`${this.prefix}_${key}`) ?? '';
    }

    public remove(key: string)
    {
        localStorage.removeItem(`${this.prefix}_${key}`)
    }
}
