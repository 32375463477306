<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-back-button defaultHref="/login" text="Login"></ion-back-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
    <form [formGroup]="resetPasswordForm" (ngSubmit)="submit()">
        <ion-item lines="none" class="ion-margin-vertical">
            <ion-label class="ion-text-wrap">
                <h1 class="header">Reset Password</h1>
                <p>Add your new password and confirm it below to finish recovering your account.</p>
            </ion-label>
        </ion-item>

        <ion-item>
            <ion-label position="stacked">Password</ion-label>
            <ion-input type="password" formControlName="password" minlength="8" placeholder="New password." autocomplete="new-password"></ion-input>

            <ion-note slot="error" *ngIf="submitted && resetPasswordForm.controls.password.invalid">
                <span *ngIf="resetPasswordForm.controls.password.hasError('required')">Enter a new password.</span>
                <span *ngIf="resetPasswordForm.controls.password.hasError('minlength')">Your password must have a minimum of 8 characters.<br /><br /></span>
                <span *ngIf="resetPasswordForm.controls.password.hasError('passwordStrength') && !resetPasswordForm.controls.password.errors['passwordStrength']['hasUpperCase']">Your password must have an upper case character.<br /><br /></span>
                <span *ngIf="resetPasswordForm.controls.password.hasError('passwordStrength') && !resetPasswordForm.controls.password.errors['passwordStrength']['hasLowerCase']">Your password must have a lower case character.<br /><br /></span>
                <span *ngIf="resetPasswordForm.controls.password.hasError('passwordStrength') && !resetPasswordForm.controls.password.errors['passwordStrength']['hasNumeric']">Your password must have a numeric character.<br /><br /></span>
                <span *ngIf="resetPasswordForm.controls.password.hasError('passwordStrength') && !resetPasswordForm.controls.password.errors['passwordStrength']['hasSymbol']">Your password must have a symbol character.<br /><br /></span>
            </ion-note>
        </ion-item>

        <ion-item>
            <ion-label position="stacked">Confirm Password</ion-label>
            <ion-input type="password" formControlName="password_confirmation" placeholder="Confirm new password." autocomplete="new-password"></ion-input>

            <ion-note slot="error" *ngIf="submitted && resetPasswordForm.controls.password_confirmation.hasError('required')">Confirm your new password.<br /><br /></ion-note>
            <ion-note slot="error" *ngIf="submitted && resetPasswordForm.controls.password_confirmation.hasError('isConfirmed')">The passwords do no match.</ion-note>
        </ion-item>

        <ion-grid>
            <ion-row>
                <ion-col>
                    <ion-button type="submit" expand="block">Reset Password</ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </form>
</ion-content>

