import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService}                        from "../../../shared/services/auth.service";
import {Router}                             from "@angular/router";
import {UiService}                          from "../../../shared/services/ui.service";

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent
{
    public errors: any;
    public submitted: boolean = false;
    public forgotPasswordForm = new FormGroup({
        email: new FormControl<string | null>(null, [Validators.required, Validators.email]),
    });

    public constructor(
        private authService: AuthService,
        private router: Router,
        private uiService: UiService,
    )
    {
    }

    public submit()
    {
        this.submitted = true;

        if (this.forgotPasswordForm.valid) {
            this.authService.forgotPassword(this.forgotPasswordForm.controls.email.value).subscribe({
                next: response => {
                    this.uiService.toast(response.message);
                    this.router.navigate(['/login']);
                },
                error: async response => {
                    this.submitted = false;
                    this.errors = response.error.errors;

                    await this.uiService.errorToast(response.error.message);
                }
            });
        }
    }
}
