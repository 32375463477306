import {EventEmitter, Injectable}                                                                             from '@angular/core';
import {Router}                                                                                 from "@angular/router";
import {IonicSafeString, LoadingController, MenuController, PopoverController, ToastController} from '@ionic/angular';
import {MenuPopoverComponent}                                                                   from "../components/menu-popover/menu-popover.component";
import {AuthService}                                                                            from "./auth.service";

@Injectable({
    providedIn: 'root'
})
export class UiService
{
    public loading: any;
    public popover: any;
    public siteUpdates: EventEmitter<any> = new EventEmitter<any>();

    public constructor(
        public router: Router,
        public toastController: ToastController,
        public popoverController: PopoverController,
        public authService: AuthService,
        private loadingCtrl: LoadingController,
        private menu: MenuController,
    )
    {
    }

    public async toast(message: string | IonicSafeString, color: string | undefined = undefined, duration: number = 3500)
    {
        const toast = await this.toastController.create({message, duration, color});
        await toast.present();
    }

    public async errorToast(message: string, duration: number = 3500)
    {
        await this.toast(message, 'danger', duration);
    }

    public async showLoading(message: string = "Loading...")
    {
        this.loading = await this.loadingCtrl.create({
            message,
        });

        this.loading.present();
    }

    public endLoading()
    {
        this.loading.dismiss();
    }

    public navigate(url: string = 'app/sites')
    {
        this.router.navigateByUrl(url);
    }

    // TODO: Make a type for the menu items
    public async showMenuPopover(event: Event, menuItems: any[])
    {
        this.popover = await this.popoverController.create({
            component: MenuPopoverComponent,
            componentProps: {
                menuItems: menuItems
            },
            event: event,
        });

        await this.popover.present();
    }

    public async showNavigationPopover(event: Event)
    {
        await this.showMenuPopover(event, [
            {
                text: 'Home',
                isButton: true,
                isDetail: false,
                isDisabled: false,
                action: this.navigate.bind(this) // TODO: Refactor away from func binding, due to perf, goto change dect
            },
            {
                text: 'Logout',
                isButton: true,
                isDetail: false,
                isDisabled: false,
                action: this.logout.bind(this) // TODO: Refactor away from func binding, due to perf, goto change dect
            }
        ]);
    }

    public dismissMenuPopover()
    {
        this.popover.dismiss();
    }

    public logout()
    {
        this.authService.logout().subscribe({
            next: () => {
                this.menu.close();
            },
            error: (response) => this.authService.handleError(response)
        });
    }
}
