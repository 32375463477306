import {Pipe}         from "@angular/core";
import {DomSanitizer} from "@angular/platform-browser";

@Pipe({name: 'safeHtml'})
export class SafeHtmlPipe
{
    public constructor(private sanitizer: DomSanitizer)
    {
    }

    public transform(style: string)
    {
        return this.sanitizer.bypassSecurityTrustHtml(style);
    }
}
