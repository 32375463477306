import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function ConfirmedPasswordValidator(): ValidatorFn
{
    return (control: AbstractControl): ValidationErrors | null => {
        return control.parent?.get('password')?.value === control.value
            ? null
            : { isConfirmed: true };
    };
}
