import {Injectable}                                       from '@angular/core';
import {Capacitor}                                        from "@capacitor/core";
import {Directory, Encoding, Filesystem, WriteFileResult} from "@capacitor/filesystem";
import {BaseService}                                      from "./base.service";
import { UiService } from './ui.service';

@Injectable({
    providedIn: 'root'
})
export class FileService extends BaseService {
    public directory: Directory = Capacitor.getPlatform() == 'android' ? Directory.Library : Directory.Documents;

    protected constructor(
        private uiService: UiService
    ) {
        super();
    }

    public async saveFromUrl(
        response: any,
        path: string,
        directory: Directory = this.directory,
        encoding: Encoding = Encoding.UTF8,
        recursive: boolean = true
    ): Promise<{result: WriteFileResult, name: string}>
    {
        const blob: Blob = new Blob([response.body], { type: response.headers.get('Content-Type') });
        const fileName = this.generateRandomFileName(15);

        const fileWriteResult: WriteFileResult = await Filesystem.writeFile({
            path: `${path}/${fileName}`,
            data: await this.blobToBase64(blob),
            directory,
            recursive
        });

        if (!Capacitor.isNativePlatform()) {
          fileWriteResult.uri = this.makeWebUri(fileWriteResult.uri);
        } else {
          const uriParts = fileWriteResult.uri.split(Capacitor.getPlatform() == 'ios' ? '/Documents/' : 'files/');
          if (uriParts.length > 1) {
              fileWriteResult.uri = uriParts[1];
          }
        }

        return {result: fileWriteResult, name: fileName};
    }

    public async save(data: string,
        path: string,
        directory: Directory = this.directory,
        encoding: Encoding = Encoding.UTF8,
        recursive: boolean = true
    ): Promise<{result: WriteFileResult, name: string}>
    {
        let fileName = this.generateRandomFileName(15);
        const fileWriteResult: WriteFileResult = await Filesystem.writeFile({
            path: `${path}/${fileName}`,
            data: data,
            directory,
            recursive
        });

        if (!Capacitor.isNativePlatform()) {
          fileWriteResult.uri = this.makeWebUri(fileWriteResult.uri);
        } else {
          const uriParts = fileWriteResult.uri.split(Capacitor.getPlatform() == 'ios' ? '/Documents/' : 'files/');
          if (uriParts.length > 1) {
              fileWriteResult.uri = uriParts[1];
          }
        }

        return {result: fileWriteResult, name: fileName};
    }

    public async read(
        path: string,
        directory: Directory = this.directory,
        encoding: Encoding = Encoding.UTF8,
    )
    {
        let options = {
            path: path,
            directory: directory,
        }

        let data = await Filesystem.readFile(options);
        data.data = `data:image/jpeg;base64,${data.data}`;
        return data;
    }

    public async deleteFile(
        path: string,
        directory: Directory = this.directory
    )
    {
        await Filesystem.deleteFile({path, directory});
    };

    public async deleteFolder(
        path: string | string[],
        recursive: boolean = false,
        directory: Directory = this.directory,
    ): Promise<any|null>
    {
        try {
            return Array.isArray(path)
                ? path.map(async (key: string) => {
                    await Filesystem.rmdir({path: key, directory, recursive}).catch(res => {

                    });
                })
                : await Filesystem.rmdir({path, directory, recursive});
        } catch (err) {
            return err;
        }
    };

    private async blobToBase64(blob: Blob): Promise<string>
    {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onerror = reject;
            reader.onabort = reject;

            reader.onload = () => resolve(reader.result as string);
            reader.readAsDataURL(blob);
        });
    }

    private getFileName(headers: any): string
    {
        let fileName = null;
        const disposition = headers.get('Content-Disposition');

        if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);

            if (matches != null && matches[1]) {
                fileName = matches[1].replace(/['"]/g, '');
            }
        }

        return fileName;
    }

    private generateRandomFileName(length: number): string
    {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;

        for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result + ".jpeg";
    }

    private makeWebUri(uri: string): any
    {
        return uri.replace(/^\/[A-Z]*\//, '');
    }
}
