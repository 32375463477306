import {Injectable}     from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree
}                       from '@angular/router';
import {Observable, of} from 'rxjs';
import {switchMap}      from 'rxjs/operators';
import {AuthService}    from "../services/auth.service";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad
{
    /**
     * Constructor
     */
    constructor(
        private authService: AuthService,
        private router: Router
    )
    {
    }

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        return this.check(state.url);
    }

    public canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        return this.check(state.url);
    }

    public canLoad(
        route: Route,
        segments: UrlSegment[]
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        return this.check('/');
    }

    private check(redirectUrl: string): Observable<boolean>
    {
        return this.authService.check().pipe(
            switchMap((authenticated: boolean) => {
                if (!authenticated) {
                    this.router.navigate(['/login'], {queryParams: {redirectUrl}});
                    return of(false);
                }

                return of(true);
            })
        );
    }
}
