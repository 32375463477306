<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-back-button defaultHref="/login" text="Login"></ion-back-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="submit()">
        <ion-item lines="none" class="ion-margin-vertical">
            <ion-label class="ion-text-wrap">
                <h1 class="header">Forgot Password</h1>
                <p>Enter the email associated with your account and we'll send you instructions to reset your password.</p>
            </ion-label>
        </ion-item>

        <ion-item>
            <ion-label position="stacked">Email</ion-label>
            <ion-input type="email" formControlName="email" placeholder="john.smith@example.co.uk" autocomplete="email"></ion-input>

            <ion-note slot="error" *ngIf="submitted && forgotPasswordForm.controls.email.hasError('required')">Email is required.</ion-note>
            <ion-note slot="error" *ngIf="submitted && forgotPasswordForm.controls.email.hasError('email')">Please enter a valid email.</ion-note>
        </ion-item>

        <ion-grid>
            <ion-row>
                <ion-col>
                    <ion-button type="submit" expand="block">Send Recovery Link</ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </form>
</ion-content>

