import {HttpClient}                from '@angular/common/http';
import {Injectable}                from '@angular/core';
import {BaseService}               from './base.service';
import {Observable, of, switchMap} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class DataService extends BaseService
{
    protected constructor(
        protected httpClient: HttpClient
    )
    {
        super();
    }

    public list(path: string, params: any = null): Observable<any>
    {
        return this.httpClient.get(`${this.apiUrl}/${path}`, {params: params})
            .pipe(switchMap((response: any) => {
                return of(response);
            }));
    }

    public show(path: string, id: string | number): Observable<any>
    {
        return this.httpClient.get(`${this.apiUrl}/${path}/${id}`)
            .pipe(switchMap((response: any) => {
                return of(response);
            }));
    }

    public create(path: string, data: any): Observable<any>
    {
        return this.httpClient.post<any>(`${this.apiUrl}/${path}`, data)
            .pipe(switchMap((response: any) => {
                return of(response);
            }));
    }

    public update(path: string, data: any): Observable<any>
    {
        return this.httpClient.put<any>(`${this.apiUrl}/${path}`, data)
            .pipe(switchMap((response: any) => {
                return of(response);
            }));
    }

    public delete(path: string): Observable<any>
    {
        return this.httpClient.delete<void>(`${this.apiUrl}/${path}`);
    }
}
