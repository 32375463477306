import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function PasswordValidator(): ValidatorFn
{
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;

        if (!value) {
            return null;
        }

        const passwordStrength = {
            hasUpperCase: /[A-Z]+/.test(value),
            hasLowerCase: /[a-z]+/.test(value),
            hasNumeric: /[0-9]+/.test(value),
            hasSymbol: /[#$^+=!*()@%&]+/.test(value),
        };

        return !(isValid(passwordStrength))
            ? {passwordStrength}
            : null;
    }

    function isValid(passwordStrength: { hasUpperCase: boolean; hasLowerCase: boolean; hasNumeric: boolean; hasSymbol: boolean; })
    {
        return passwordStrength.hasUpperCase && passwordStrength.hasLowerCase && passwordStrength.hasNumeric && passwordStrength.hasSymbol;
    }
}
