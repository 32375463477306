import {enableProdMode}                     from '@angular/core';
import {platformBrowserDynamic}             from '@angular/platform-browser-dynamic';
import {defineCustomElements}               from '@ionic/pwa-elements/loader';
import {defineCustomElements as jeepSqlite} from 'jeep-sqlite/loader';
import {AppModule}                          from './app/app.module';
import {environment}                        from './environments/environment';

if (environment.production) {
    enableProdMode();
}

jeepSqlite(window);

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));

defineCustomElements(window);
