import {environment} from "../../environments/environment";

export const databaseSchema: any = {
    database: environment.db.name,
    version: 2,
    encrypted: false,
    mode: "full",
    tables: [
        {
            name: "sites",
            schema: [
                {column: "id", value: "INTEGER PRIMARY KEY NOT NULL"},
                {column: "name", value: "VARCHAR(125) NOT NULL"},
                {column: "customer", value: "VARCHAR(125) NOT NULL"},
                {column: "status", value: "VARCHAR(125) NOT NULL"},
                {column: "price_modifier", value: "INTEGER UNSIGNED NULL"},
                {column: "address_1", value: "VARCHAR(125) NOT NULL"},
                {column: "address_2", value: "VARCHAR(125) NULL"},
                {column: "address_3", value: "VARCHAR(125) NULL"},
                {column: "notes", value: "VARCHAR(125) NULL"},
                {column: "postcode", value: "VARCHAR(125) NOT NULL"},
                {column: "author", value: "VARCHAR(125) NOT NULL"},
                {column: "created_at", value: "TIMESTAMP NULL"},
                {column: "updated_at", value: "TIMESTAMP NULL"},
                {column: "deleted_at", value: "TIMESTAMP NULL"},
                {column: "synced_at", value: "TIMESTAMP NULL"},
            ],
        },
        {
            name: "site_manufacturers",
            schema: [
                {column: "site_id", value: "INTEGER NOT NULL"},
                {column: "manufacturer_id", value: "INTEGER NOT NULL"},
                { constraint: 'PK_site_id_manufacturer_id', value: 'PRIMARY KEY (site_id,manufacturer_id)'},
            ],
        },
        {
            name: "buildings",
            schema: [
                {column: "id", value: "INTEGER PRIMARY KEY NOT NULL"},
                {column: "name", value: "VARCHAR(125) NOT NULL"},
                {column: "location", value: "VARCHAR(125) NULL"},
                {column: "site_id", value: "BIGINT UNSIGNED NOT NULL"},
                {column: "author", value: "VARCHAR(125) NOT NULL"},
                {column: "created_at", value: "TIMESTAMP NULL"},
                {column: "updated_at", value: "TIMESTAMP NULL"},
                {column: "deleted_at", value: "TIMESTAMP NULL"},
                {column: "synced_at", value: "TIMESTAMP NULL"},
            ],
        },
        {
            name: "floors",
            schema: [
                {column: "id", value: "INTEGER PRIMARY KEY NOT NULL"},
                {column: "name", value: "VARCHAR(125) NOT NULL"},
                {column: "building_id", value: "BIGINT UNSIGNED NOT NULL"},
                {column: "author", value: "VARCHAR(125) NOT NULL"},
                {column: "created_at", value: "TIMESTAMP NULL"},
                {column: "updated_at", value: "TIMESTAMP NULL"},
                {column: "deleted_at", value: "TIMESTAMP NULL"},
                {column: "synced_at", value: "TIMESTAMP NULL"},
            ],
        },
        {
            name: "drawings",
            schema: [
                {column: "id", value: "INTEGER PRIMARY KEY NOT NULL"},
                {column: "name", value: "VARCHAR(125) NOT NULL"},
                {column: "floor_id", value: "BIGINT NOT NULL"},
                {column: "plan_path", value: "LONGTEXT NULL"},
                {column: "plan_width", value: "VARCHAR(125) NULL"},
                {column: "plan_height", value: "VARCHAR(125) NULL"},
                {column: "survey_completed_at", value: "TIMESTAMP NULL"},
                {column: "author", value: "VARCHAR(125) NOT NULL"},
                {column: "created_at", value: "TIMESTAMP NULL"},
                {column: "updated_at", value: "TIMESTAMP NULL"},
                {column: "deleted_at", value: "TIMESTAMP NULL"},
                {column: "synced_at", value: "TIMESTAMP NULL"},
            ],
        },
        {
            name: "pins",
            schema: [
                {column: "id", value: "CHAR(36) PRIMARY KEY NOT NULL"},
                {column: "server_id", value: "BIGINT NULL"},
                {column: "name", value: "INTEGER NOT NULL"},
                {column: "pin_identifier", value: "VARCHAR(125) NOT NULL"},
                {column: "drawing_id", value: "BIGINT UNSIGNED NOT NULL"},
                {column: "latitude", value: "DECIMAL(11, 7) NOT NULL"},
                {column: "longitude", value: "DECIMAL(11, 7) NOT NULL"},
                {column: "author", value: "VARCHAR(125) NOT NULL"},
                {column: "author_id", value: "INTEGER NULL"},
                {column: "is_local", value: "TINYINT(1) NOT NULL"},
                {column: "created_at", value: "TIMESTAMP NULL"},
                {column: "updated_at", value: "TIMESTAMP NULL"},
                {column: "deleted_at", value: "TIMESTAMP NULL"},
                {column: "synced_at", value: "TIMESTAMP NULL"},
            ],
        },
        {
            name: "pin_histories",
            schema: [
                {column: "id", value: "CHAR(36) PRIMARY KEY NOT NULL"},
                {column: "server_id", value: "BIGINT NULL"},
                {column: "pin_id", value: "CHAR(36) NOT NULL"},
                {column: "status_id", value: "INTEGER NOT NULL"},
                {column: "rating_type", value: "VARCHAR(125) NOT NULL"},
                {column: "flame_resistance", value: "VARCHAR(125) NULL"},
                {column: "arc_rating", value: "VARCHAR(125) NULL"},
                {column: "notes", value: "TEXT NULL"},
                {column: "author", value: "VARCHAR(125) NOT NULL"},
                {column: "author_id", value: "INTEGER NULL"},
                {column: "is_local", value: "TINYINT(1) NOT NULL"},
                {column: "created_at", value: "TIMESTAMP NULL"},
                {column: "updated_at", value: "TIMESTAMP NULL"},
                {column: "deleted_at", value: "TIMESTAMP NULL"},
                {column: "synced_at", value: "TIMESTAMP NULL"},
            ],
        },
        {
            name: "pin_history_item_types",
            schema: [
                {column: "pin_history_id", value: "CHAR(36) NOT NULL"},
                {column: "item_type_id", value: "BIGINT UNSIGNED NOT NULL"},
                {column: "quantity", value: "INTEGER NOT NULL"},
                {column: "title", value: "VARCHAR(125) NOT NULL"},
                {constraint: 'PK_pin_history_id_item_type_id', value: 'PRIMARY KEY (pin_history_id,item_type_id)'},
            ],
        },
        {
            name: "pin_history_products",
            schema: [
                {column: "pin_history_id", value: "CHAR(36) NOT NULL"},
                {column: "manufacturer_product_id", value: "BIGINT UNSIGNED NOT NULL"},
                {column: "quantity", value: "INTEGER NOT NULL"},
                {column: "measure_1", value: "DECIMAL(8, 2) NULL"},
                {column: "measure_2", value: "DECIMAL(8, 2) NULL"},
                {column: "measure_3", value: "DECIMAL(8, 2) NULL"},
                {column: "author_id", value: "INTEGER NULL"},
                {column: "created_at", value: "TIMESTAMP NULL"},
                {column: "updated_at", value: "TIMESTAMP NULL"},
                {column: "deleted_at", value: "TIMESTAMP NULL"},
            ],
        },
        {
            name: "pin_history_photos",
            schema: [
                {column: "id", value: "INTEGER PRIMARY KEY NOT NULL"},
                {column: "pin_history_id", value: "CHAR(36) NOT NULL"},
                {column: "server_id", value: "INTEGER NULL"},
                {column: "file_path", value: "LONGTEXT NULL"},
                {column: "file_name", value: "VARCHAR(125) NOT NULL"},
                {column: "created_at", value: "TIMESTAMP NULL"},
                {column: "updated_at", value: "TIMESTAMP NULL"},
                {column: "deleted_at", value: "TIMESTAMP NULL"},
            ],
        },
        {
            name: "products",
            schema: [
                {column: "id", value: "BIGINT PRIMARY KEY NOT NULL"},
                {column: "name", value: "VARCHAR(125) NOT NULL"},
                {column: "status_id", value: "BIGINT UNSIGNED DEFAULT '1' NOT NULL"},
                {column: "measure_id", value: "INTEGER NOT NULL"},
                {column: "author_id", value: "INTEGER NOT NULL"},
                {column: "created_at", value: "TIMESTAMP NULL"},
                {column: "updated_at", value: "TIMESTAMP NULL"},
                {column: "deleted_at", value: "TIMESTAMP NULL"},
            ],
        },
        {
            name: "product_measures",
            schema: [
                {column: "id", value: "BIGINT PRIMARY KEY NOT NULL"},
                {column: "unit", value: "VARCHAR(125) NULL"},
                {column: "symbol", value: "VARCHAR(5) NULL"},
                {column: "input_unit", value: "VARCHAR(125) NULL"},
                {column: "input_symbol", value: "VARCHAR(4) NULL"},
                {column: "number_of_inputs", value: "TINYINT(1) NULL"},
                {column: "deleted_at", value: "TIMESTAMP NULL"},
            ],
        },
        {
            name: "manufacturers",
            schema: [
                {column: "id", value: "BIGINT PRIMARY KEY NOT NULL"},
                {column: "name", value: "VARCHAR(125) NOT NULL"},
                {column: "status_id", value: "BIGINT UNSIGNED DEFAULT '1' NOT NULL"},
                {column: "author_id", value: "INTEGER NOT NULL"},
                {column: "created_at", value: "TIMESTAMP NULL"},
                {column: "updated_at", value: "TIMESTAMP NULL"},
                {column: "deleted_at", value: "TIMESTAMP NULL"},
            ],
        },
        {
            name: "manufacturer_product",
            schema: [
                {column: "id", value: "BIGINT PRIMARY KEY NOT NULL"},
                {column: "product_id", value: "BIGINT UNSIGNED NOT NULL"},
                {column: "manufacturer_id", value: "BIGINT UNSIGNED NOT NULL"},
                {column: "author_id", value: "INTEGER NOT NULL"},
                {column: "created_at", value: "TIMESTAMP NULL"},
                {column: "updated_at", value: "TIMESTAMP NULL"},
                {column: "deleted_at", value: "TIMESTAMP NULL"},
            ],
        },
        {
            name: "manufacturer_prices",
            schema: [
                {column: "id", value: "BIGINT PRIMARY KEY NOT NULL"},
                {column: "manufacturer_product_id", value: "BIGINT UNSIGNED NOT NULL"},
                {column: "tier", value: "DECIMAL(8, 2) NOT NULL"},
                {column: "operative_cost", value: "DECIMAL(8, 2) NOT NULL"},
                {column: "subcontractor_cost", value: "DECIMAL(8, 2) NOT NULL"},
                {column: "material_cost", value: "DECIMAL(8, 2) NOT NULL"},
                {column: "sell_price", value: "DECIMAL(8, 2) NOT NULL"},
                {column: "start_at", value: "DATE NULL"},
                {column: "end_at", value: "DATE NULL"},
                {column: "author_id", value: "INTEGER NOT NULL"},
                {column: "created_at", value: "TIMESTAMP NULL"},
                {column: "updated_at", value: "TIMESTAMP NULL"},
                {column: "deleted_at", value: "TIMESTAMP NULL"},
            ],
        },
        {
            name: "item_types",
            schema: [
                {column: "id", value: "BIGINT PRIMARY KEY NOT NULL"},
                {column: "title", value: "VARCHAR(125) NOT NULL"},
                {column: "description", value: "VARCHAR(255) NULL"},
            ],
        },
        {
            name: "server_sync",
            schema: [
                {column: "id", value: "INTEGER PRIMARY KEY NOT NULL"},
                {column: "last_synced_at", value: "TIMESTAMP NOT NULL"},
                {column: "data", value: "TEXT NULL"},
            ],
        },
    ]
};
