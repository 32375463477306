import {Injectable}     from '@angular/core';
import {Observable, of} from 'rxjs';
import {environment}    from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export abstract class BaseService
{
    protected apiUrl: string;

    protected constructor()
    {
        this.apiUrl = environment.api.url;
    }

    public handleError<T>(result?: T)
    {
        return (error: any): Observable<T> => {
            console.error(error);
            return of(result as T);
        };
    }
}
