import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import {
	CapacitorSQLite, SQLiteDBConnection, SQLiteConnection,
	capSQLiteChanges, capSQLiteValues, capEchoResult, capSQLiteResult,
	capNCDatabasePathResult
} from '@capacitor-community/sqlite';
import { BehaviorSubject } from 'rxjs';
import { environment } from "../../../environments/environment";
import { UiService } from './ui.service';

@Injectable({
	providedIn: 'root'
})
export class SqliteService {
	public sqlite: SQLiteConnection;
	public isService: boolean = false;
	public platform: string;
	public sqlitePlugin: any;
	public native: boolean = false;
	public dbInstance: SQLiteDBConnection;
	public dbReady = new BehaviorSubject(false);
	public dbVersion: number = 4;

	constructor(
		private uiService: UiService
	) {
	}

	// ------------------ Custom ------------------

	public async checkConnection() {
		return new Promise<void>(async (resolve, reject) => {
			try {
				// Check Connections Consistency
				let ret = await this.checkConnectionsConsistency();
				if (!ret.result) {
					let connection = await this.createConnection(environment.db.name, false, 'no-encryption', this.dbVersion)
					await connection.open();
					this.dbInstance = connection;
					this.dbReady.next(true);
				}
				return resolve();
			} catch (error) {
				reject();
				throw new Error(' your message ');
			}
		});
	}

	public async checkConnectionOld() {
		return await this.retrieveConnection(environment.db.name)
			.then(async res => {
				console.log('using existing connection');
				await res.open();
				return res;
			}).catch(async () => {
				console.log('creating new connection');
				const db = await this.createConnection(environment.db.name, false, 'no-encryption', this.dbVersion);
				await db.open();
				return db;
			});
	}

	public async deleteDatabase(database: string = environment.db.name) {
		await CapacitorSQLite.isDatabase({ database }).then(async (isDatabase: capSQLiteResult) => {
			if (isDatabase.result) {
				await CapacitorSQLite.deleteDatabase({ database });
			}
		});
	}

	async checkQuotaAndNotify() {
		const quota = await navigator.storage.estimate();
		const totalSpace = quota.quota;
		const usedSpace = quota.usage;

		const usedPercentage = (usedSpace / totalSpace) * 100;
		if (usedPercentage >= 95) {
			await this.uiService.errorToast('You are within 5% of your total storage limit! Please SYNC UP and remove yourself from sites you do not need!', 7000);
		}
	}

	// ------------------ Custom END ------------------

	//Boiler Plate Below
	/**
	 * Plugin Initialization
	 */
	initializePlugin(): Promise<boolean> {
		return new Promise(resolve => {
			this.platform = Capacitor.getPlatform();
			if (this.platform === 'ios' || this.platform === 'android') {
				this.native = true;
			}
			this.sqlitePlugin = CapacitorSQLite;
			this.sqlite = new SQLiteConnection(this.sqlitePlugin);
			this.isService = true;
			resolve(true);
		});
	}

	getPlatform() {
		return this.platform;
	}

	/**
	 * Echo a value
	 * @param value
	 */
	async echo(value: string): Promise<capEchoResult> {
		if (this.sqlite != null) {
			try {
				const ret = await this.sqlite.echo(value);
				return Promise.resolve(ret);
			} catch (err: any) {
				return Promise.reject(new Error(err));
			}
		} else {
			return Promise.reject(new Error("no connection open"));
		}
	}

	async isSecretStored(): Promise<capSQLiteResult> {
		if (!this.native) {
			return Promise.reject(new Error(`Not implemented for ${this.platform} platform`));
		}
		if (this.sqlite != null) {
			try {
				return Promise.resolve(await this.sqlite.isSecretStored());
			} catch (err: any) {
				return Promise.reject(new Error(err));
			}
		} else {
			return Promise.reject(new Error(`no connection open`));
		}
	}

	async setEncryptionSecret(passphrase: string): Promise<void> {
		if (!this.native) {
			return Promise.reject(new Error(`Not implemented for ${this.platform} platform`));
		}
		if (this.sqlite != null) {
			try {
				return Promise.resolve(await this.sqlite.setEncryptionSecret(passphrase));
			} catch (err: any) {
				return Promise.reject(new Error(err));
			}
		} else {
			return Promise.reject(new Error(`no connection open`));
		}

	}

	async changeEncryptionSecret(passphrase: string, oldpassphrase: string): Promise<void> {
		if (!this.native) {
			return Promise.reject(new Error(`Not implemented for ${this.platform} platform`));
		}
		if (this.sqlite != null) {
			try {
				return Promise.resolve(await this.sqlite.changeEncryptionSecret(passphrase, oldpassphrase));
			} catch (err: any) {
				return Promise.reject(new Error(err));
			}
		} else {
			return Promise.reject(new Error(`no connection open`));
		}

	}

	/**
	 * addUpgradeStatement
	 * @param database
	 * @param toVersion
	 * @param statements
	 */
	async addUpgradeStatement(database: string,
		toVersion: number, statements: any[])
		: Promise<void> {
		if (this.sqlite != null) {
			try {
				await this.sqlite.addUpgradeStatement(database, [{ toVersion: toVersion, statements }]);
				return Promise.resolve();
			} catch (err: any) {
				return Promise.reject(new Error(err));
			}
		} else {
			return Promise.reject(new Error(`no connection open for ${database}`));
		}
	}

	/**
	 * get a non-conformed database path
	 * @param path
	 * @param database
	 * @returns Promise<capNCDatabasePathResult>
	 * @since 3.3.3-1
	 */
	async getNCDatabasePath(folderPath: string, database: string): Promise<capNCDatabasePathResult> {
		if (this.sqlite != null) {
			try {
				const res: capNCDatabasePathResult = await this.sqlite.getNCDatabasePath(
					folderPath, database);
				return Promise.resolve(res);
			} catch (err: any) {
				return Promise.reject(new Error(err));
			}
		} else {
			return Promise.reject(new Error(`no connection open for ${database}`));
		}

	}

	/**
	 * Create a non-conformed database connection
	 * @param databasePath
	 * @param version
	 * @returns Promise<SQLiteDBConnection>
	 * @since 3.3.3-1
	 */
	async createNCConnection(databasePath: string, version: number): Promise<SQLiteDBConnection> {
		if (this.sqlite != null) {
			try {
				const db: SQLiteDBConnection = await this.sqlite.createNCConnection(
					databasePath, version);
				if (db != null) {
					return Promise.resolve(db);
				} else {
					return Promise.reject(new Error(`no db returned is null`));
				}
			} catch (err: any) {
				return Promise.reject(new Error(err));
			}
		} else {
			return Promise.reject(new Error(`no connection open for ${databasePath}`));
		}

	}

	/**
	 * Close a non-conformed database connection
	 * @param databasePath
	 * @returns Promise<void>
	 * @since 3.3.3-1
	 */
	async closeNCConnection(databasePath: string): Promise<void> {
		if (this.sqlite != null) {
			try {
				await this.sqlite.closeNCConnection(databasePath);
				return Promise.resolve();
			} catch (err: any) {
				return Promise.reject(new Error(err));
			}
		} else {
			return Promise.reject(new Error(`no connection open for ${databasePath}`));
		}
	}

	/**
	 * Check if a non-conformed databaseconnection exists
	 * @param databasePath
	 * @returns Promise<capSQLiteResult>
	 * @since 3.3.3-1
	 */
	async isNCConnection(databasePath: string): Promise<capSQLiteResult> {
		if (this.sqlite != null) {
			try {
				return Promise.resolve(await this.sqlite.isNCConnection(databasePath));
			} catch (err: any) {
				return Promise.reject(new Error(err));
			}
		} else {
			return Promise.reject(new Error(`no connection open`));
		}

	}

	/**
	 * Retrieve a non-conformed database connection
	 * @param databasePath
	 * @returns Promise<SQLiteDBConnection>
	 * @since 3.3.3-1
	 */
	async retrieveNCConnection(databasePath: string): Promise<SQLiteDBConnection> {
		if (this.sqlite != null) {
			try {
				return Promise.resolve(await this.sqlite.retrieveNCConnection(databasePath));
			} catch (err: any) {
				return Promise.reject(new Error(err));
			}
		} else {
			return Promise.reject(new Error(`no connection open for ${databasePath}`));
		}
	}

	/**
	 * Check if a non conformed database exists
	 * @param databasePath
	 * @returns Promise<capSQLiteResult>
	 * @since 3.3.3-1
	 */
	async isNCDatabase(databasePath: string): Promise<capSQLiteResult> {
		if (this.sqlite != null) {
			try {
				return Promise.resolve(await this.sqlite.isNCDatabase(databasePath));
			} catch (err: any) {
				return Promise.reject(new Error(err));
			}
		} else {
			return Promise.reject(new Error(`no connection open`));
		}
	}

	/**
	 * Create a connection to a database
	 * @param database
	 * @param encrypted
	 * @param mode
	 * @param version
	 */
	async createConnection(database: string, encrypted: boolean,
		mode: string, version: number, readonly?: boolean
	): Promise<SQLiteDBConnection> {
		if (this.sqlite != null) {
			try {
				/*                if(encrypted) {
									if(this.native) {
										const isSet = await this.sqlite.isSecretStored()
										if(!isSet.result) {
											return Promise.reject(new Error(`no secret phrase registered`));
										}
									}
								}
				*/
				const readOnly = readonly ? readonly : false;
				const db: SQLiteDBConnection = await this.sqlite.createConnection(
					database, encrypted, mode, version, readOnly);
				if (db != null) {
					return Promise.resolve(db);
				} else {
					return Promise.reject(new Error(`no db returned is null`));
				}
			} catch (err: any) {
				return Promise.reject(new Error(err));
			}
		} else {
			return Promise.reject(new Error(`no connection open for ${database}`));
		}
	}

	/**
	 * Close a connection to a database
	 * @param database
	 */
	async closeConnection(database: string, readonly?: boolean): Promise<void> {
		if (this.sqlite != null) {
			try {
				const readOnly = readonly ? readonly : false;
				await this.sqlite.closeConnection(database, readOnly);
				return Promise.resolve();
			} catch (err: any) {
				return Promise.reject(new Error(err));
			}
		} else {
			return Promise.reject(new Error(`no connection open for ${database}`));
		}
	}

	/**
	 * Retrieve an existing connection to a database
	 * @param database
	 */
	async retrieveConnection(database: string, readonly?: boolean):
		Promise<SQLiteDBConnection> {
		if (this.sqlite != null) {
			try {
				const readOnly = readonly ? readonly : false;
				return Promise.resolve(await this.sqlite.retrieveConnection(database, readOnly));
			} catch (err: any) {
				return Promise.reject(new Error(err));
			}
		} else {
			return Promise.reject(new Error(`no connection open for ${database}`));
		}
	}

	/**
	 * Retrieve all existing connections
	 */
	async retrieveAllConnections():
		Promise<Map<string, SQLiteDBConnection>> {
		if (this.sqlite != null) {
			try {
				const myConns = await this.sqlite.retrieveAllConnections();
				let keys = [...myConns.keys()];
				keys.forEach((value) => {
					console.log("Connection: " + value);
				});

				return Promise.resolve(myConns);
			} catch (err: any) {
				return Promise.reject(new Error(err));
			}
		} else {
			return Promise.reject(new Error(`no connection open`));
		}
	}

	/**
	 * Close all existing connections
	 */
	async closeAllConnections(): Promise<void> {
		if (this.sqlite != null) {
			try {
				return Promise.resolve(await this.sqlite.closeAllConnections());
			} catch (err: any) {
				return Promise.reject(new Error(err));
			}
		} else {
			return Promise.reject(new Error(`no connection open`));
		}
	}

	/**
	 * Check if connection exists
	 * @param database
	 */
	async isConnection(database: string, readonly?: boolean): Promise<capSQLiteResult> {
		if (this.sqlite != null) {
			try {
				const readOnly = readonly ? readonly : false;
				return Promise.resolve(await this.sqlite.isConnection(database, readOnly));
			} catch (err: any) {
				return Promise.reject(new Error(err));
			}
		} else {
			return Promise.reject(new Error(`no connection open`));
		}
	}

	/**
	 * Check Connections Consistency
	 * @returns
	 */
	async checkConnectionsConsistency(): Promise<capSQLiteResult> {
		if (this.sqlite != null) {
			debugger;
			try {
				const res = await this.sqlite.checkConnectionsConsistency();
				debugger;
				return Promise.resolve(res);
			} catch (err: any) {
				return Promise.reject(new Error(err));
			}
		} else {
			return Promise.reject(new Error(`no connection open`));
		}
	}

	/**
	 * Check if database exists
	 * @param database
	 */
	async isDatabase(database: string): Promise<capSQLiteResult> {
		if (this.sqlite != null) {
			try {
				return Promise.resolve(await this.sqlite.isDatabase(database));
			} catch (err: any) {
				return Promise.reject(new Error(err));
			}
		} else {
			return Promise.reject(new Error(`no connection open`));
		}
	}

	/**
	 * Get the list of databases
	 */
	async getDatabaseList(): Promise<capSQLiteValues> {
		if (this.sqlite != null) {
			try {
				return Promise.resolve(await this.sqlite.getDatabaseList());
			} catch (err: any) {
				return Promise.reject(new Error(err));
			}
		} else {
			return Promise.reject(new Error(`no connection open`));
		}
	}

	/**
	 * Get Migratable databases List
	 */
	async getMigratableDbList(folderPath?: string): Promise<capSQLiteValues> {
		if (!this.native) {
			return Promise.reject(new Error(`Not implemented for ${this.platform} platform`));
		}
		if (this.sqlite != null) {
			try {
				if (!folderPath || folderPath.length === 0) {
					return Promise.reject(new Error(`You must provide a folder path`));
				}
				return Promise.resolve(await this.sqlite.getMigratableDbList(folderPath));
			} catch (err: any) {
				return Promise.reject(new Error(err));
			}
		} else {
			return Promise.reject(new Error(`no connection open`));
		}
	}

	/**
	 * Add "SQLite" suffix to old database's names
	 */
	async addSQLiteSuffix(folderPath?: string, dbNameList?: string[]): Promise<void> {
		if (!this.native) {
			return Promise.reject(new Error(`Not implemented for ${this.platform} platform`));
		}
		if (this.sqlite != null) {
			try {
				const path: string = folderPath ? folderPath : "default";
				const dbList: string[] = dbNameList ? dbNameList : [];
				return Promise.resolve(await this.sqlite.addSQLiteSuffix(path, dbList));
			} catch (err: any) {
				return Promise.reject(new Error(err));
			}
		} else {
			return Promise.reject(new Error(`no connection open`));
		}
	}

	/**
	 * Delete old databases
	 */
	async deleteOldDatabases(folderPath?: string, dbNameList?: string[]): Promise<void> {
		if (!this.native) {
			return Promise.reject(new Error(`Not implemented for ${this.platform} platform`));
		}
		if (this.sqlite != null) {
			try {
				const path: string = folderPath ? folderPath : "default";
				const dbList: string[] = dbNameList ? dbNameList : [];
				return Promise.resolve(await this.sqlite.deleteOldDatabases(path, dbList));
			} catch (err: any) {
				return Promise.reject(new Error(err));
			}
		} else {
			return Promise.reject(new Error(`no connection open`));
		}
	}

	/**
	 * Moves database files from a given folder to the database location where
	 * they can be read, it also changes their suffix.
	 * @param folderPath the folder to move from
	 * @param dbNameList the files to move, empty list means all the files
	 * @returns
	 */
	async moveDatabasesAndAddSuffix(folderPath?: string, dbNameList?: string[]): Promise<void> {
		if (!this.native) {
			throw new Error(`Not implemented for ${this.platform} platform`);
		}
		if (this.sqlite != null) {
			const path: string = folderPath ? folderPath : "default";
			const dbList: string[] = dbNameList ? dbNameList : [];
			return this.sqlite.moveDatabasesAndAddSuffix(path, dbList);
		} else {
			throw new Error(`can't move the databases`);
		}
	}

	async getFromHTTPRequest(url: string, overwrite?: boolean): Promise<void> {
		const mOverwrite: boolean = overwrite != null ? overwrite : true;
		if (url.length === 0) {
			return Promise.reject(new Error(`Must give an url to download`));
		}
		if (this.sqlite != null) {
			return this.sqlite.getFromHTTPRequest(url, mOverwrite);
		} else {
			throw new Error(`can't download the database`);
		}
	}

	/**
	 * Import from a Json Object
	 * @param jsonstring
	 */
	async importFromJson(jsonstring: string): Promise<capSQLiteChanges> {
		if (this.sqlite != null) {
			try {
				return Promise.resolve(await this.sqlite.importFromJson(jsonstring));
			} catch (err: any) {
				return Promise.reject(new Error(err));
			}
		} else {
			return Promise.reject(new Error(`no connection open`));
		}

	}

	/**
	 * Is Json Object Valid
	 * @param jsonstring Check the validity of a given Json Object
	 */
	async isJsonValid(jsonstring: string): Promise<capSQLiteResult> {
		if (this.sqlite != null) {
			try {
				return Promise.resolve(await this.sqlite.isJsonValid(jsonstring));
			} catch (err: any) {
				return Promise.reject(new Error(err));
			}
		} else {
			return Promise.reject(new Error(`no connection open`));
		}

	}

	/**
	 * Copy databases from public/assets/databases folder to application databases folder
	 */
	async copyFromAssets(overwrite?: boolean): Promise<void> {
		const mOverwrite: boolean = overwrite != null ? overwrite : true;
		console.log(`&&&& mOverwrite ${mOverwrite}`);
		if (this.sqlite != null) {
			try {
				return Promise.resolve(await this.sqlite.copyFromAssets(mOverwrite));
			} catch (err: any) {
				return Promise.reject(new Error(err));
			}
		} else {
			return Promise.reject(new Error(`no connection open`));
		}
	}

	/**
	 * Initialize the Web store
	 */
	async initWebStore(): Promise<void> {
		if (this.platform !== 'web') {
			return Promise.reject(new Error(`not implemented for this platform: ${this.platform}`));
		}
		if (this.sqlite != null) {
			try {
				await this.sqlite.initWebStore();
				return Promise.resolve();
			} catch (err: any) {
				return Promise.reject(new Error(err));
			}
		} else {
			return Promise.reject(new Error(`no connection open`));
		}
	}

	/**
	 * Save a database to store
	 * @param database
	 */
	async saveToStore(database: string = 'fire_survey_pro_v2'): Promise<void> {
		if (this.platform !== 'web') {
			return console.error('Not implemented for this platform:', this.platform);
		}

		if (this.sqlite != null && this.sqlite !== undefined) {
			try {
				await this.sqlite.saveToStore(database);

				return Promise.resolve();
			} catch (err: any) {
				return Promise.reject(new Error(err));
			}
		} else {
			await this.uiService.errorToast("Error saving data, please let developer know.", 10000);
			return Promise.reject(new Error("Error saving data, please let developer know."));
		}
	}
}
