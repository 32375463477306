import {Component, OnInit}                  from '@angular/core';
import {ActivatedRoute, Router}             from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UiService}                          from "../../../shared/services/ui.service";
import {AuthService}                        from "../../../shared/services/auth.service";
import {PasswordValidator}                  from "../../../shared/validators/password.validator";
import {ConfirmedPasswordValidator}         from "../../../shared/validators/confirmedPassword.validator";

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit
{
    public token: string;
    public email: string;
    public errors: any;
    public submitted: boolean = false;
    public resetPasswordForm = new FormGroup({
        password: new FormControl<string | null>(null, [Validators.required, Validators.minLength(8), PasswordValidator()]),
        password_confirmation: new FormControl<string | null>(null, [Validators.required, ConfirmedPasswordValidator()]),
    });

    public constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private uiService: UiService,
    )
    {
    }

    public ngOnInit()
    {
        this.email = this.route.snapshot.queryParamMap.get('email');
        this.token = this.route.snapshot.paramMap.get('token');
    }

    public submit()
    {
        this.submitted = true;

        if (this.resetPasswordForm.valid) {
            this.authService.resetPassword(this.token, this.email, this.resetPasswordForm.controls.password.value, this.resetPasswordForm.controls.password_confirmation.value).subscribe({
                next: response => {
                    this.uiService.toast(response.message);
                    this.router.navigate(['/login']);
                },
                error: async response => {
                    this.submitted = false;
                    this.errors = response.error.errors;

                    await this.uiService.errorToast(response.error.message);
                }
            });
        }
    }
}
