import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { DataService } from "./data.service";
import { FileService } from "./file.service";
import { SqliteService } from './sqlite.service';
import { UiService } from './ui.service';
// @ts-ignore
import { query } from 'js-query-builder';

@Injectable({
	providedIn: 'root'
})
export class DatabaseService {
	public dbName: string = environment.db.name;

	public constructor(
		private uiService: UiService,
		private sqlite: SqliteService,
		private dataService: DataService,
		private fileService: FileService
	) {

	}

	public async getProducts() {
		return new Promise<void>(async (resolve, reject) => {
			await this.sqlite.dbInstance.execute('DELETE FROM products;');
			await this.sqlite.dbInstance.execute('DELETE FROM manufacturer_product;');
			await this.sqlite.dbInstance.execute('DELETE FROM manufacturer_prices;');
			let pageSize = 30;

			const productQuery = query(`products`)
				.include('status', 'originalMeasure', 'originalMeasureCount', 'author', 'prices.manufacturer').filter('hybrid_trashed', 'yes');

			this.dataService.list(productQuery.param('page[number]', 1)
				.param('page[size]', pageSize).build()).subscribe(
					async response => {
						await this.storeProducts(response.data);

						if (response.meta.total_pages > 1) {
							for (let index = 2; index <= response.meta.total_pages; index++) {
								const url = productQuery.param('page[number]', index).param('page[size]', pageSize).build();
								this.dataService.list(url).subscribe(
									async response => {
										await this.storeProducts(response.data);
									}
								);
							}
						}
						resolve();
					}
				)
		});
	}

	private async storeProducts(products: any) {
		return new Promise<void>(async (resolve, reject) => {
			let insertProducts = <any>[];
			let manufacturerProducts = <any>[];
			let manufacturerPrices = <any>[];

			for (let index = 0; index < products.length; index++) {
				const product = products[index];

				insertProducts.push({
					id: product?.id,
					name: product?.name,
					status_id: product?.status?.id,
					measure_id: product?.measure?.id,
					display_measure: product?.display_measure,
					author_id: product?.author?.id,
					created_at: product?.created_at,
					updated_at: product?.updated_at,
					deleted_at: product?.deleted_at
				});

				for (let secondIndex = 0; secondIndex < product.prices.length; secondIndex++) {
					const price = product.prices[secondIndex];

					console.log(price, price?.manufacturer?.id);

					manufacturerProducts.push({
						id: price?.manufacturer_product_id,
						product_id: product?.id,
						manufacturer_id: price?.manufacturer?.id,
						author_id: price?.author?.id,
						created_at: price?.created_at,
						updated_at: price?.updated_at,
						deleted_at: price?.deleted_at
					});

					manufacturerPrices.push({
						id: price?.id,
						manufacturer_product_id: price?.manufacturer_product_id,
						tier: price?.tier,
						operative_cost: price?.operative_cost,
						subcontractor_cost: price?.subcontractor_cost,
						material_cost: price?.material_cost,
						sell_price: price?.sell_price,
						start_at: price?.start_at,
						end_at: price?.end_at,
						author_id: price?.author?.id,
						created_at: price?.created_at,
						updated_at: price?.updated_at,
						deleted_at: price?.deleted_at ?? null
					});
				}
			}

			await this.insert('products', insertProducts);
			await this.insert('manufacturer_product', manufacturerProducts);
			await this.insert('manufacturer_prices', manufacturerPrices);
			resolve();
		});
	}

	public async getProductMeasures() {
		return new Promise<void>(async (resolve, reject) => {
			await this.sqlite.dbInstance.execute('DELETE FROM product_measures;');

			const productQuery = query(`products/measures`).build();
			this.dataService.list(productQuery).subscribe(
				async response => {
					await this.storeMeasures(response.data);
					resolve();
				}
			)
		});
	}

	private async storeMeasures(measures: any) {
		return new Promise<void>(async (resolve, reject) => {
			let insertMeasures = <any>[];
			for (let index = 0; index < measures.length; index++) {
				const measure = measures[index];

				insertMeasures.push({
					id: measure.id,
					unit: measure.unit,
					symbol: measure.symbol,
					input_unit: measure.input_unit,
					input_symbol: measure.input_symbol,
					number_of_inputs: measure.number_of_inputs,
					deleted_at: measure.deleted_at
				});
			}

			await this.insert('product_measures', insertMeasures);
			resolve();
		});
	}

	public async getManufacturers() {
		return new Promise<void>(async (resolve, reject) => {
			await this.sqlite.dbInstance.execute('DELETE FROM manufacturers;');
			let pageSize = 30;

			const manufacturerQuery = query(`manufacturers`)
				.include('status', 'author').filter('hybrid_trashed', 'yes');

			this.dataService.list(manufacturerQuery.param('page[number]', 1)
				.param('page[size]', pageSize).build()).subscribe(
					async response => {
						await this.storeManufacturers(response.data);

						if (response.meta.total_pages > 1) {
							for (let index = 2; index <= response.meta.total_pages; index++) {
								const url = manufacturerQuery.param('page[number]', index).param('page[size]', pageSize).build();
								this.dataService.list(url).subscribe(
									async response => {
										await this.storeManufacturers(response.data);
									}
								);
							}
						}
						resolve();
					}
				)
		});
	}

	private async storeManufacturers(manufacturers: any) {
		return new Promise<void>(async (resolve, reject) => {
			let insertManufacturers = <any>[];
			for (let index = 0; index < manufacturers.length; index++) {
				const manufacturer = manufacturers[index];

				insertManufacturers.push({
					id: manufacturer?.id,
					name: manufacturer?.name,
					status_id: manufacturer?.status?.id,
					author_id: manufacturer?.author?.id,
					created_at: manufacturer?.created_at,
					updated_at: manufacturer?.updated_at,
					deleted_at: manufacturer?.deleted_at
				});
			}
			await this.insert('manufacturers', insertManufacturers);
			resolve();
		});
	}

	public async getManufacturerProducts() {
		return new Promise<void>(async (resolve, reject) => {

		});
	}

	public async createItemTypes() {
		return new Promise<void>(async (resolve, reject) => {
			await this.sqlite.dbInstance.execute('DELETE FROM item_types;');

			const url = query(`defects`).param('page[number]', 1).param('page[size]', 150).filter('hybrid_trashed', 'yes').build();
			this.dataService.list(url).subscribe({
				next: async response => {
					if (!response.data.length) {
						return reject(new Error('No defects could be retrieved.'));
					}

					let itemTypes = [];
					for (let index = 0; index < response.data.length; index++) {
						const item_type = response.data[index];

						itemTypes.push({
							id: item_type.id,
							title: item_type.title,
							description: item_type.description,
							deleted_at: item_type.deleted_at ?? null
						});
					}

					await this.insert('item_types', itemTypes);

					resolve();
				},
				error: (response) => this.dataService.handleError(response)
			});
		});
	}

	public async insert(table: string, data: any[]) {
		return new Promise<void>(async (resolve, reject) => {
			try {
				if (data && data.length) {
					data = data.map(item => this.removeObjectsAndArrays({ ...item }));
					let flatValues: any[] = [];
					const columns = data.map((obj) => Object.keys(obj));
					const values = data.map((obj) => Object.values(obj));

					// TODO: Mix in with the above values declaration
					values.forEach((arr: any) => {
						arr.forEach(function (item: any) {
							flatValues.push(item);
						});
					});

					let placeholders = data.map(() => `(${columns[0].map(c => '?').join(', ')})`).join(', ');

					let query = `INSERT OR
                             REPLACE INTO ${table} (${columns[0].join(', ')})
                             VALUES ${placeholders};`;

					await this.sqlite.dbInstance.run(query, flatValues);
					await this.sqlite.saveToStore();
					resolve();
				} else {
					resolve();
					// reject("No Data Passed");
				}
			} catch (error) {
				console.log("Error in insert", error);
				reject(error);
			}
		});
	}

	public async update(table: string, data: any) {
		return new Promise<void>(async (resolve, reject) => {
			data = this.removeObjectsAndArrays(data);
			if (data && Object.keys(data).length) {
				const columns = Object.keys(data);
				const values = Object.values(data);
				// Assuming 'id' is the identifier column
				let identifierColumn = 'id'; // Replace 'id' with the actual identifier column name
				let identifierValue = data[identifierColumn];
				if (identifierValue === undefined) {
					reject("Identifier value not provided");
					return;
				}
				// Filter out the identifier from the update fields
				const updateColumns = columns.filter(column => column !== identifierColumn);
				const updateValues = updateColumns.map(column => data[column]);
				// Generate the SET part of the SQL query
				let setStatements = updateColumns.map(column => `${column} = ?`).join(', ');
				let query = `UPDATE ${table}
                         SET ${setStatements}
                         WHERE ${identifierColumn} = ?;`;
				// Add the identifier value to the end of the values array for the WHERE clause
				updateValues.push(identifierValue);
				await this.sqlite.dbInstance.run(query, updateValues);
				await this.sqlite.saveToStore();
				resolve();
			} else {
				reject("No Data Passed");
			}
		});
	}

	public async truncate(table: string | string[]) {
		if (table.constructor === Array) {
			for (const key of table) {
				// debugger;
				console.log((await this.sqlite.dbInstance.execute(`DELETE FROM ${key};`)).changes);
			}
		} else {
			await this.sqlite.dbInstance.execute(`DELETE FROM ${table};`);
		}

		await this.sqlite.saveToStore();
	}

	public async removeOldData(siteIdList: number[], buildingIdList: number[], floorIdList: number[], drawingIdList: number[]) {
		this.uiService.loading.message = `Removing out of date data`;

		if (siteIdList.length) {
			await this.sqlite.dbInstance.execute(`DELETE FROM sites WHERE id NOT IN (${siteIdList});`);
			await this.sqlite.dbInstance.execute(`DELETE FROM site_manufacturers WHERE site_id NOT IN (${siteIdList});`);
		} else {
			await this.truncate('sites');
			await this.truncate('site_manufacturers');
		}

		if (buildingIdList.length) {
			await this.sqlite.dbInstance.execute(`DELETE FROM buildings WHERE id NOT IN (${buildingIdList});`);
		} else {
			await this.truncate('buildings');
		}

		if (floorIdList.length) {
			await this.sqlite.dbInstance.execute(`DELETE FROM floors WHERE id NOT IN (${floorIdList});`);
		} else {
			await this.truncate('floors');
		}

		if (drawingIdList.length) {
			let toBeDeletedDrawingIds = await (await this.sqlite.dbInstance.query(`SELECT id FROM drawings WHERE id NOT IN (${drawingIdList})`)).values.map(p => p.id);
			let drawingsToBeDeleted = toBeDeletedDrawingIds.map(drawingId => `drawings/${drawingId}`);

			//console.log("Drawings to be deleted", drawingsToBeDeleted);
			await this.fileService.deleteFolder(drawingsToBeDeleted, true);

			let pins = await (await this.sqlite.dbInstance.query(`SELECT id FROM pins WHERE drawing_id NOT IN (${drawingIdList})`)).values.map(p => p.id);

			if (pins.length) {
				let pinHistories = await (await this.sqlite.dbInstance.query(`SELECT id FROM pin_histories WHERE pin_id IN ('${pins}')`)).values.map(p => p.id);

				await this.sqlite.dbInstance.execute(`DELETE FROM pins WHERE id IN ('${pins}');`);

				if (pinHistories.length) {
					await this.sqlite.dbInstance.execute(`DELETE FROM pin_histories WHERE id IN ('${pinHistories}');`);
					await this.sqlite.dbInstance.execute(`DELETE FROM pin_history_item_types WHERE pin_history_id IN ('${pinHistories}');`);
					await this.sqlite.dbInstance.execute(`DELETE FROM pin_history_products WHERE pin_history_id IN ('${pinHistories}');`);
					await this.sqlite.dbInstance.execute(`DELETE FROM pin_history_photos WHERE pin_history_id IN ('${pinHistories}');`);

					let photosToBeDeleted = pinHistories.map(pinHistoryId => `pin-histories/${pinHistoryId}`);
					// console.log("Photos to be deleted", photosToBeDeleted);
					for (let photo of photosToBeDeleted) {
						await this.fileService.deleteFolder(photo, true);
					}

				} else {
					await this.truncate(['pin_histories', 'pin_history_item_types', 'pin_history_products', 'pin_history_photos']);
					await this.fileService.deleteFolder('pin_histories', true);
				}
			}
		} else {
			await this.truncate('drawings');
			await this.fileService.deleteFolder('drawings', true);
			await this.truncate('pins');
			await this.truncate(['pin_histories', 'pin_history_item_types', 'pin_history_products', 'pin_history_photos']);
			await this.fileService.deleteFolder('pin-histories', true);
		}
		await this.sqlite.dbInstance.exportToJson('partial').then(res => {
			console.log("DB Export Partial", res);
		});
		await this.sqlite.dbInstance.deleteExportedRows().then(res => {
			console.log("Delete old data - deleteExportedRows()", res);
		}).catch(res => {
			console.log("Delete old data - deleteExportedRows()", res);
		});
		await this.sqlite.saveToStore();
	}

	public removeObjectsAndArrays(obj: any) {
		Object.keys(obj).forEach(key => {
			if (typeof obj[key] === 'object' && obj[key] !== null) {
				if (Array.isArray(obj[key]) || Object.prototype.toString.call(obj[key]) === '[object Object]') {
					delete obj[key];
				}
			}
		});
		return obj;
	}
}

