import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {APP_INITIALIZER, ApplicationRef, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {Router, RouteReuseStrategy} from '@angular/router';
import {ServiceWorkerModule, SwUpdate} from '@angular/service-worker';
import {JwtModule} from "@auth0/angular-jwt";
import {IonicModule, IonicRouteStrategy, ToastController} from '@ionic/angular';
import * as SentryAngular from "@sentry/angular-ivy";
import * as Sentry from "@sentry/capacitor";
import {CookieService} from "ngx-cookie-service";
import packageInfo from "../../package.json";
import {environment} from "../environments/environment";
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TenantInterceptor} from "./shared/interceptor/tenant-interceptor";
import {AppUpdateService} from "./shared/services/app-update.service";
import {LocationStrategy, PathLocationStrategy} from "@angular/common";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { GlobalErrorHandler } from "./shared/handler/global-error-handler";

function initializeAppUpdateFactory(
  appRef: ApplicationRef,
  updates: SwUpdate,
  toastController: ToastController
): () => AppUpdateService {
  return (): AppUpdateService => {
    return new AppUpdateService(appRef, updates, toastController);
  }
}

Sentry.init({
  dsn: environment.sentryDsn,
  release: `${packageInfo.name}@${packageInfo.version}+1`,
  tracesSampleRate: 1,
  environment: environment.app.env,
  dist: `1`,
  tracePropagationTargets: [
    "localhost",
    "localhost:4200",
    "vulcan-api.test",
    "192.168.1.46:8100",
    "staging.api.vulcan.hickwood.uk",
    "test.api.vulcan.hickwood.uk",
    "api.firesurveypro.co.uk"
  ]
}, SentryAngular.init);

// @ts-ignore
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    JwtModule.forRoot({
      config: {
        throwNoTokenError: false,
        tokenGetter: () => {
          // TODO: Make this take into account the prefix!!!
          return localStorage.getItem('fsp_accessToken');
        },
        allowedDomains: [
          'localhost',
          'localhost:4200',
          'vulcan-api.test',
          '192.168.1.46:8100',
          'staging.api.vulcan.hickwood.uk',
          'test.api.vulcan.hickwood.uk'
        ],
        disallowedRoutes: [
          '//vulcan-api.test/api/v1/auth',
          '//vulcan-api.test/api/v1/join/',
        ],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.serviceWorkerEnabled,
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    {provide: LocationStrategy, useClass: PathLocationStrategy},
    CookieService,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppUpdateFactory,
      deps: [ApplicationRef, SwUpdate, ToastController, SentryAngular.TraceService],
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: ErrorHandler,
      useValue: SentryAngular.createErrorHandler(),
    },
    {
      provide: SentryAngular.TraceService,
      deps: [Router]
    },
    {provide: HTTP_INTERCEPTORS, useClass: TenantInterceptor, multi: true},
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
