import {Injectable}     from '@angular/core';
import {BaseService}    from "./base.service";
import {StorageService} from "./storage.service";

@Injectable({
    providedIn: 'root'
})
export class PermissionsService extends BaseService
{
    public constructor(
        private storageService: StorageService,
    )
    {
        super();
    }

    public get roles(): String[]
    {
        return JSON.parse(this.storageService.read('roles'));
    }

    public set roles(roles: String[])
    {
        this.storageService.write('roles', JSON.stringify(roles));
    }

    public get permissions(): String[]
    {
        return JSON.parse(this.storageService.read('permissions'));
    }

    public set permissions(permissions: String[])
    {
        this.storageService.write('permissions', JSON.stringify(permissions));
    }

    public can(permission: string)
    {
        return !!this.hasPermission(permission) || this.hasRole('super-admin');
    }

    public cant(permission: string)
    {
        return !this.permissions.find(p => p === permission);
    }

    public hasPermission(permission: string) {
        return !!this.permissions.find(p => p === permission);
    }

    public hasRole(role: string)
    {
        return !!this.roles.find(p => p === role);
    }
}
